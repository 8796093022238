$(document).ready(function () {
  $.typeahead({
    input: '.js-typeahead',
    minLength: 2,
    group: false,
    asyncResult: true,
    hint: true,
    emptyTemplate: 'No result for "{{query}}".',
    source: {
      searchString: {
        dynamic: true,
        href: '/{{login}}',
        template: '{{suburb}}, {{state}} {{postcode}}',
        display: ['searchString'],
        ajax: {
          url:
            '/api/v1/location?q={{query}}' +
            (excludeRegions ? '&excludeRegions=true' : ''),
        },
      },
    },
    callback: {
      onClickAfter: function (node, _, item, event) {
        event.preventDefault();
        node.val(
          `${item.suburb} ${item.state} ${
            item.postcode ? item.postcode : ''
          }`.trim()
        );
        node.attr('result-index', item.Index);
        $.event.trigger({
          type: 'searchSelect',
          item: item,
        });
      },
      onResult: function (node, query, obj, objCount) {
        if (objCount === 0) {
          node.attr('result-index', '');
        }
      },
    },
    debug: false,
  });
});
