import './kube-addons.min.css';
import './kube-addons.min.js';

import './slim.css';
import './jquery.typeahead.css';
import './typeahead.custom.css';

import 'jquery-typeahead';
import './typeahead.custom.js';
import './sendmessage.js';
import './sendreview.js';
import './sendreply.js';
import './uglipop.js';

import Cookies from 'js-cookie';
import moment from 'moment-mini';

import './slick.min.js';
import './slick-theme.css';
import './slick.css';
import './slick-custom.css';

$(document).ready(() => {
  const event = new Event('fabLoadComplete');
  document.dispatchEvent(event);
});

window.moment = moment;
window.Cookies = Cookies;

Modernizr.on('webp', (result) => {
  var attrib = result ? 'webp' : 'fallback';
  $.each($('.smartImage'), function (index, img) {
    $(img).attr('src', $(img).data(attrib));
  });
  $.each($('.smartImageBackground'), function (index, div) {
    $(div).css('background-image', 'url(' + $(div).data(attrib) + ')');
  });
});
