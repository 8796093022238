function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

var sendReply = debounce(
  function (sender, $modal, $form) {
    var data = $form.getData();
    $K.ajax.post({
      url: '/api/v1/reviews/' + data.reviewId,
      data: data,
      success: function (response) {
        $.event.trigger({
          type: 'SendSuccess',
          item: response,
        });
        location.reload();
      },
      error: function (response) {
        $.event.trigger({
          type: 'SendError',
          item: response,
        });
      },
    });
    this.modal.close();
  },
  500, //500ms
  sendReply
);

(function ($K) {
  $K.add('module', 'sendreply', {
    init: function (app, context) {
      this.app = app;
      this.context = context;

      // modal id
      this.modalId = 'sendReplyModal_' + performance.now() * Math.pow(10, 15);

      // default params
      var defaultParams = {
        role: 'parent',
        authenticated: false,
        expired: true,
        name: '',
      };

      this.params = context.getParams(defaultParams);
      this.checkParams();

      // define service
      this.modal = app.modal;

      // build modal
      this.modalBody = this.buildModal();
    },

    checkParams: function () {
      if (this.params.role === '') {
        this.params.role = 'parent';
      }

      if (this.params.authenticated === '') {
        this.params.authenticated = false;
      }

      if (this.params.expired === '') {
        this.params.expired = true;
      }
    },

    buildModal: function () {
      var modalHtml = `<div id="${this.modalId}" class="send-reply-modal-body is-hidden">
                  <form method="post" action="/review" target="ifrm_${this.modalId}">
                      <input type="hidden" name="reviewId" value="${this.params.review}" />
                      <div class="form-rate-item">      
                          <label>Rating </label>                          
                          <div class="rating">
                            <div class="starcontainer">
                           `;
      for (var i = 1; i <= 5; i++) {
        modalHtml += `<span class="star ${
          this.params.stars >= i ? 'checked' : ''
        }"></span> `;
      }
      modalHtml += `</div>
                      </div>
                      </div>                                          
                      <div class="form-item">
                        <label>Reply to a review <small>characters (max 140)</small></label>
                        <textarea name="body" rows="6" maxlength="140" autofocus>${this.params.reply}</textarea>                      
                      </div>                    
                    </form>                  
                    <iframe id="ifrm_${this.modalId}" name="ifrm_${this.modalId}" style="display:none;"></iframe>  
                  </div>`;

      return modalHtml;
    },

    onclick: function (e, element, type) {
      if (type === 'sendreply') {
        // show modal
        this.showModal();
      }
    },

    showModal: function () {
      $K.dom('body').append(this.modalBody);

      this.modal.open({
        title: `<h1 class="text-purple is-text-center">Reply to ${this.params.name}</h1>`,
        target: `#${this.modalId}`,
        commands: {
          sendReply: {
            title: 'Save',
            classname: 'button is-big is-100',
          },
        },
      });

      $K.dom('.send-reply-modal-body').remove();
    },

    onmessage: {
      modal: {
        opened: function (sender, $modal, $form) {},
        sendReply: sendReply,
      },
    },
  });
})(Kube);
