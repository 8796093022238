(function ($K) {
  $K.add('module', 'sendreview', {
    init: function (app, context) {
      this.app = app;
      this.context = context;

      // modal id
      this.modalId = 'sendReviewModal_' + performance.now() * Math.pow(10, 15);

      // default params
      var defaultParams = {
        role: 'parent',
        authenticated: false,
        expired: true,
        name: '',
      };

      this.params = context.getParams(defaultParams);
      this.checkParams();

      // define service
      this.modal = app.modal;

      // build modal
      this.modalBody = this.buildModal();
    },

    checkParams: function () {
      if (this.params.role === '') {
        this.params.role = 'parent';
      }

      if (this.params.authenticated === '') {
        this.params.authenticated = false;
      }

      if (this.params.expired === '') {
        this.params.expired = true;
      }
    },

    buildModal: function () {
      var modalHtml = ``;

      if (this.params.role === 'parent') {
        modalHtml = `<div id="${this.modalId}" class="send-review-modal-body is-hidden">
                
                <form method="post" action="/review" target="ifrm_${this.modalId}">
                    <input type="hidden" name="to" value="${this.params.to}" />                                        

                    <div class="form-rate-item">      
                        <label>Please Rate<span class="is-req">* </span></label>                
                        <div class="rating">                            
                            <input type="radio" id="star5" name="rating" value="5" /><label for="star5" title="5 Stars"></label>
                            <input type="radio" id="star4" name="rating" value="4" /><label for="star4" title="4 Stars"></label>
                            <input type="radio" id="star3" name="rating" value="3" /><label for="star3" title="3 Stars"></label>
                            <input type="radio" id="star2" name="rating" value="2" /><label for="star2" title="2 Stars"></label>
                            <input type="radio" id="star1" name="rating" value="1" /><label for="star1" title="1 Star"></label>
                        </div>
                    </div>                    
                    <div class="feedback text-purple is-hidden is-inset-y-8 is-inset-y-4-mobile">You must enter a rating to send</div>      
                    <div class="form-item">
                      <label>Your Review</label>
                      <textarea name="body" rows="6" maxlength="280" autofocus></textarea>
                      <small>max 280 characters</small>
                    </div>                    
                  </form>                  
                  <iframe id="ifrm_${this.modalId}" name="ifrm_${this.modalId}" style="display:none;"></iframe>  
                </div>`;
      }

      return modalHtml;
    },

    onclick: function (e, element, type) {
      if (type === 'sendreview') {
        // show modal
        this.showModal();
      }
    },

    showModal: function () {
      $K.dom('body').append(this.modalBody);

      this.modal.open({
        title: `<h1 class="text-purple is-text-center">Write a review to ${this.params.name}</h1>`,
        target: `#${this.modalId}`,
        commands: {
          sendReview: {
            title: 'SEND REVIEW',
            classname: 'button is-big is-100',
          },
        },
      });

      $K.dom('.send-review-modal-body').remove();
    },

    onmessage: {
      modal: {
        opened: function (sender, $modal, $form) {},

        sendReview: function (sender, $modal, $form) {
          var stars = 0;
          for (var i = 1; i <= 5; i++) {
            if ($('#star' + i).prop('checked')) {
              stars = i;
            }
          }
          var data = $form.getData();
          data.stars = stars;
          if (stars == 0) {
            var $body = $modal.getBody();
            var $feedback = $body.find('.feedback');
            $feedback.removeClass('is-hidden');

            window.setTimeout(function () {
              $feedback.addClass('is-hidden');
            }, 5000);
          } else {
            $K.ajax.post({
              url: '/api/v1/reviews',
              data: data,
              success: function (response) {
                $.event.trigger({
                  type: 'ratingSendSuccess',
                  item: response,
                });
                location.reload();
              },
              error: function (response) {
                console.log('error sending rating');
                $.event.trigger({
                  type: 'ratingSendError',
                  item: response,
                });
              },
            });
            this.modal.close();
          }
        },
      },
    },
  });
})(Kube);
